import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <div 
      className="container-fluid text-light py-5" 
      style={{ 
        backgroundColor: '#000312', 
        backgroundImage: 'url(/about.png)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
        <div className='container'> 
          <div className="text-center mb-5">
            <h1 style={{color:'#43E1CF', fontSize:'42px', fontWeight:'700'}}>About Us</h1>
          </div>
          <div className="mb-5">
            <h2>OVERVIEW</h2>
            <p>
            Secure Domains is the first company in the GCC to offer cloud-based DNS firewall services and security through its flagship SaaS product, "DNS Armor." Established in 2023, Secure Domains was founded with the mission to deliver unparalleled cloud-based DNS security services. "DNS Armor" stands out for its top-tier security features and user-friendly interface, allowing it to be tailored precisely to meet the unique needs of each client. Its flexible implementation and highly customizable design ensure that it can be applied and scaled efficiently according to specific requirements.
            </p>
            <p>
            With headquarters in the UAE , Secure Domains is well-positioned to provide comprehensive coverage and support across the MENA region and beyond. "DNS Armor" is fully operational across 25 data centers strategically located in the US, Europe, Asia, and Africa, enabling global reach and robust service delivery.
            </p>
          </div>
          <div className="mb-5">
            <h2>OUR MISSION</h2>
            <ol>
              <li>Reshaping the cybersecurity and tech industry in DNS Security.</li>
              <li>Become a global leader in DNS Security domain.</li>
              <li>Continuous improvement of our services & evolving to provide a better experience for our clients.</li>
            </ol>
          </div>
          <div>
            <h2>OUR VISION</h2>
            <ol>
              <li>To become a pioneer and a leading provider of Multi Tenancy based Cloud DNS Firewall and security globally starting from the Middle East and then expanding globally.</li>
              <li>To set new standards for cybersecurity excellence and digital transformation.</li>
              <li>To continue growing and build partnerships with top Cloud Service Providers in the region.</li>
            </ol>
          </div>
        </div>
    </div>
  );
}

export default AboutUs;


