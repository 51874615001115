import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
import '@fontsource/poppins/700.css'; // Weight 700

const FullDescription = () => {
    return (
        <>
            <div className="container mt-5 text-white" style={{ fontFamily: 'Poppins' }}>
                <h2 className="fw-bold fs-2 fs-md-3">What is DNS Armor</h2>
                <p>
                    <span style={{ color: '#4D8C96', fontWeight: '600' }}>DNS Armor</span> is a SaaS based platform which offers cloud based DNS security services, where it utilizes recursive DNS communication to fortify
                    and secure the network infrastructure against modern malware threats like phishing and ransomware attacks. By intercepting and mitigating
                    attacks at the DNS level, DNS Armor provides scalable security measures as it preemptively detects and prevents attacks and threats that other
                    solutions commonly overlook and fail to detect.
                </p>
                <h2 className="fw-bold fs-2 fs-md-3">Market Differentiators</h2>
                <p>
                    <span style={{ color: '#4D8C96', fontWeight: '600' }}>1. Modular Architecture:</span> DNS Armor ™ boasts a unique modular design for confidential logs archiving and storage. Unlike competitors, it
                    ensures that logs and data remain within the customer's assigned geolocation service center, complying with local data residency
                    regulations. This modular approach enables distributed and local logs archiving, making it cloud-friendly for markets where
                    centralized storage violates data residency laws.
                </p>
                <p><span style={{ color: '#4D8C96', fontWeight: '600' }}>2. Service Availability:</span> DNS Armor is available across 25 global data centers which were strategically selected to make it possible to serve clients
                    across the globe and to store data over a wide array of data centers, where many of these data centers are located in critical markets and
                    regions such as the GCC, US, EU, Asia, Australia and Africa. . Utilizing this fact with the modular design of DNS ArmorTM, compliance with local
                    data residency regulations, top notch privacy & security and low latency communication is achieved for GCC countries & many other markets.</p>
                <p>
                <span style={{ color: '#4D8C96', fontWeight: '600' }}>3. Managed Service Providers (MSPs) Support:</span> DNS ARMOR ™ is designed to fully support Managed Service Providers (MSPs) with multi-tenancy
                    capabilities for both control-plane and data-plane. This empowers MSPs to offer value-added services without additional upfront investments
                    in standalone tools. The flexible deployment options seamlessly integrate with existing offerings and accommodate future growth.
                </p>
                <p>
                <span style={{ color: '#4D8C96', fontWeight: '600' }}>4. Multi-Tenancy Support:</span> DNS Armor™ employs a robust multi-tenancy architecture, allowing a single instance of the software to cater to
                    multiple tenants or user accounts. Each tenant's data is isolated and invisible to others, ensuring data security and privacy. This
                    architecture enhances efficiency and scalability while maintaining strict data segregation.
                </p>
                <p>
                <span style={{ color: '#4D8C96', fontWeight: '600' }}>5. Automated Security Feeds:</span> DNS Armor™ comes equipped with 22 Threat Intelligence and 29 Web Categorization feeds in the Cloud DNS
                    Firewall. These feeds provide real-time threat intelligence, enabling proactive security decisions. Additionally, the system allows exporting
                    of feeds in the form of RPZ to trusted on-premise DNS resolvers, enhancing overall threat protection.
                </p>
                <p>
                <span style={{ color: '#4D8C96', fontWeight: '600' }}>6. Highly Customizable & Sizable:</span> Thanks to the unique design & the SaaS based nature of,DNS Armor,the sizing and features can be selected
                    with great flexibility and ease, according to the actual technical needs and budgetary concerns of each individual client.
                    Unlike most competitors, the client is able to efficiently select and utilize the services provided by DNS Armor TM without having to compromise or
                    to buy a solution that is more complex and more expensive than their actual requirements.
                    With coordination with Secure Domains, the client can also add or remove features anytime according to their needs and requirements.
                </p>
                <p>
                <span style={{ color: '#4D8C96', fontWeight: '600' }}>7. Friendly User Interface & Easy Network Management:</span> DNS ArmorTM,was designed with the user’s experience as a primary concern & a top
                    priority; therefore, Secure Domains has worked diligently to ensure that the use of DNS ArmorTM is easy, intuitive and efficient.
                    The administrator can easily manage the network & implement policies via the use of a centralized control center which utilizes a neatly
                    organized,  fast & responsive graphical user interface ( GUI ) that is easy to navigate.

                </p>
            </div>

        </>
    );
};

export default FullDescription;
