import React from 'react';
import NavbarComponent from './components/Navbar/Navbar';
import BlogSummary from './components/Blogs/BlogsSummary';
import FooterSection from './components/Footer/Footer';
const Media = () => {


  return (
    <>
    <NavbarComponent />
    <BlogSummary />
     <FooterSection />
    </>
  );
}

export default Media;
