import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
import '@fontsource/poppins/700.css'; // Weight 700

const DnsDescription = () => {
    return (
        <>
            <div className="container mt-5 text-white" style={{ fontFamily: 'Poppins' }}>
                <h2 className="fw-bold fs-2 fs-md-3">What is DNS?</h2>
                <p>
                    <span className="text-primary fw-bold text-decoration-underline">DNS (Domain Name System)</span> is a critical network service that translates user-friendly domain names into numerical IP addresses, enabling internet accessibility. However, its pivotal role also makes it a prime target for cyber threats. Hackers exploit DNS vulnerabilities for various attacks, including malware distribution and phishing campaigns.
                </p>
                <p>
                    In response, <span className="fw-bold">DNS Armor</span>™ provides a Cloud DNS Firewall solution. It offers centralized visibility and precise control over security policies, leveraging advanced filtering capabilities and dynamic threat intelligence feeds to detect and mitigate a wide range of threats, from malware to data exfiltration, thus safeguarding networks in an increasingly interconnected digital landscape.
                </p>
            </div>
          
        </>
    );
};

export default DnsDescription;
