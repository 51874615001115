import React, { useRef,useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
import DnsDescription from './ProductText';
import NeedDnsDescription from './NeedDns';
import TextImageComponent from './DnsExamples';
import FullDescription from './FullProductInfo';
import ProjectKeyFeatures from './ProjectKeyFeatures';
import DnsApplications from './DnsApplications';
const buttonStyle = {
    width: '100%',
    borderRadius: '75px',
    backgroundColor: '#3CCAC9',
    color: '#010311',
    fontWeight: '600',
    height: '44px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    border: 'none',
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s ease', // Smooth transition for background color
};

const buttonHoverStyle = {
    backgroundColor: '#2c9f9f',
};

const titleStyle = {
    fontFamily: 'Poppins',
    fontSize: '52px',
    fontWeight: '700',
    color: 'white',
    marginBottom: '40px',
};

const sectionTitleStyle = {
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: '600',
    margin: '50px 0 20px 0',
};

const sectionTextStyle = {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '400',
    marginBottom: '30px',
};

const ButtonGrid = () => {
    const [hoveredButton, setHoveredButton] = useState(null);

    // Create refs for each section
    const dnsRef = useRef(null);
    const securityRef = useRef(null);
    const useCasesRef = useRef(null);
    const armorRef = useRef(null);
    const advantagesRef = useRef(null);
    const keyFeaturesRef = useRef(null);
    const applicationsRef = useRef(null);
    const datasheetRef = useRef(null);

    const handleMouseEnter = (index) => setHoveredButton(index);
    const handleMouseLeave = () => setHoveredButton(null);

    const handleButtonClick = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const renderButton = (text, index, ref) => (
        <div className="col-md-3 col-sm-6 mb-3" key={index}>
            <button
                className="btn"
                style={{
                    ...buttonStyle,
                    backgroundColor: hoveredButton === index ? buttonHoverStyle.backgroundColor : buttonStyle.backgroundColor,
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleButtonClick(ref)}
            >
                {text}
            </button>
        </div>
    );

    return (
        <>
            <div className="container text-center mt-3">
                <div className="row">
                    <div className="col-12">
                        <h1 style={titleStyle}>
                            DNS Armor
                        </h1>
                    </div>
                    {renderButton('What is DNS?', 0, dnsRef)}
                    {renderButton('The need for DNS security', 1, securityRef)}
                    {renderButton('DNS Security Use Cases', 2, useCasesRef)}
                    {renderButton('What is DNS armor', 3, armorRef)}
                </div>
            </div>
            <div className="container text-center mt-3">
                <div className="row">
                    {renderButton('Advantages of DNS armor', 4, advantagesRef)}
                    {renderButton('Key Features of DNS armor', 5, keyFeaturesRef)}
                    {renderButton('Applications of DNS armor', 6, applicationsRef)}
                    {renderButton('Datasheet', 7, datasheetRef)}
                </div>
            </div>

            {/* Sections for each button */}
            <div ref={dnsRef} style={{ marginTop: '100px' }}>
                <DnsDescription />
            </div>
            <div ref={securityRef} style={{ marginTop: '100px' }}>
               <NeedDnsDescription />
            </div>
            <div ref={useCasesRef} style={{ marginTop: '100px' }}>
               <TextImageComponent />
            </div>
            <div ref={armorRef} style={{ marginTop: '100px' }}>
                <FullDescription />
            </div>
            <div ref={advantagesRef} style={{ marginTop: '100px' }}>
              <ProjectKeyFeatures />
            </div>
            <div ref={keyFeaturesRef} style={{ marginTop: '100px' }}>
               {/* <ProjectKeyFeatures />                 */}
            </div>
            <div ref={applicationsRef} style={{ marginTop: '100px' }}>
               <DnsApplications />
            </div>
            <div ref={datasheetRef} style={{ marginTop: '100px' }}>
               
            </div>
        </>
    );
};

export default ButtonGrid;
