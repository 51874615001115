import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
import '@fontsource/poppins/700.css'; // Weight 700

const DnsApplications = () => {
    return (
        <>
            <div className="container mt-5 text-white" style={{ fontFamily: 'Poppins' }}>
                <h2 className="fw-bold fs-2 fs-md-3">Applications of DNS ARMOR™</h2>
                <div className="row">
                    <div className="col-12">
                        <p>
                            <span style={{color:'#4D8C96',fontWeight:'600'}}>1. Mitigating Malware Attacks:</span> DNS ARMOR™ offers specialized feeds designed to detect and block various types of malware threats. By
                            proactively identifying and blocking malicious activity at the DNS level, it complements existing security solutions and reduces their
                            burden, ensuring a comprehensive defense against evolving malware attacks.
                        </p>
                        <p>
                            <span style={{color:'#4D8C96',fontWeight:'600'}}>2. Combatting Ransomware Threats:</span> Ransomware poses a significant risk to organizations, often exploiting DNS for key exchanges and
                            delivering malicious payloads. DNS ARMOR™ enhances ransomware defense by detecting and thwarting these attacks, preventing
                            unauthorized encryption of files and potential data loss.
                        </p>
                        <p>
                            <span style={{color:'#4D8C96',fontWeight:'600'}}>3. Preventing Phishing Incidents:</span> Phishing attacks rely on deceiving users into visiting malicious websites or downloading harmful software.
                            Leveraging threat intelligence, DNS ARMOR™ automatically blocks access to abusive sites, safeguarding against data theft and credential
                            compromise, even on personal devices within corporate networks.
                        </p>
                        <p>
                            <span style={{color:'#4D8C96',fontWeight:'600'}}>4. Utilizing Versatile Security & Network Administration Functions:</span> DNS Armor is not limited to only protecting from DNS based threats, it can
                            also be used to efficiently manage and optimize the network and its policies such as allowing or blocking certain websites and many other rules
                            can be enforced as well.
                            The use of DNS Armor can help other other technologies and devices that are part of the network perform more efficiently & it can provide very
                            accurate monitoring of traffic data and bandwidth usage.
                        </p>
                        <p>
                            <span style={{color:'#4D8C96',fontWeight:'600'}}>5. Expanding the Database of Known Security Threats to Avoid New & Emerging Threats:</span> DNS Armor is always expanding its database of
                            malicious domains by utilizing advanced algorithms to dynamically update the server with new threat intelligence feeds from various robust
                            & trustworthy sources. With the servers being continuously updated and alert to new malicious websites and entities, the security of the
                            network is drastically increased as DNS ArmorTM protect the network from all of the rapidly increasing threats over the internet without having
                            to manually update the database whenever a new threat is discovered.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mt-5 text-center text-white">
      <h1>DATASHEET</h1>
      <p>For more information and technical details, you can download DNS Armor’s datasheet by clicking on the link below.</p>
      <a href="/datasheet.pdf" download="datasheet.pdf">
        <img 
          src="/dow.png" 
          alt="Download Datasheet" 
          className="img-fluid" 
          style={{ maxHeight: '80px', maxWidth: '100%' }} 
        />
      </a>
    </div>
            <br/>
        </>
    );
};

export default DnsApplications;
