import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        comments: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = async () => {
        const email = "mohammed.w.s.1988@gmail.com";
        const password = "Admin@123";
    
        try {
            const res = await axios.post('https://api-dev.secure-domains.org/v1/api/auth/login', {
                userName: email,
                password: password,
            });

            if (res.data && res.data.message === 'Success') {
                const token = res.data.data?.authToken;
                console.log('Token:', token); // Log the token to the console

                // Store the token in localStorage
                if (token) {
                    localStorage.setItem('authToken', token);
                } else {
                    console.error('Token not found in response.');
                }
            } else {
                console.error('Login failed:', res.data);
            }
        } catch (error) {
            console.error('Error during login:', error.message);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Call onSubmit to log in and store the token
        await onSubmit();

        // Retrieve the token from localStorage
        const token = localStorage.getItem('authToken');
        console.log('Retrieved Token:', token); // Log the token to the console

        if (!token) {
            alert('No token found. Please login first.');
            return;
        }
    
        try {
            const response = await fetch('https://api-dev.secure-domains.org/v1/api/user/sendWebsiteEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Include token in the Authorization header
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                alert('Your message has been sent!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    comments: '',
                });
            } else {
                alert('Failed to send the message.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred. Please try again later.');
        }
    };
    
    return (
        <>
            <style>
                {`
                    .custom-input::placeholder {
                        color: white !important;
                        opacity: 0.6;
                    }
                `}
            </style>
            <div className="container mt-5" style={{ backgroundColor: '#000312' }}>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3 animated-text">
                        <div className="col-md-6 mb-3">
                            <input
                                type="text"
                                className="form-control custom-input"
                                style={{ backgroundColor: '#161826', color: 'white', height: '82px', borderRadius: '20px', opacity: '0.8', border: 'none' }}
                                placeholder="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <input
                                type="email"
                                className="form-control custom-input"
                                style={{ backgroundColor: '#161826', color: 'white', height: '82px', borderRadius: '20px', opacity: '0.8', border: 'none' }}
                                placeholder="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-3 animated-text">
                        <div className="col-md-6 mb-3">
                            <input
                                type="text"
                                className="form-control custom-input"
                                style={{ backgroundColor: '#161826', color: 'white', height: '82px', borderRadius: '20px', opacity: '0.8', border: 'none' }}
                                placeholder="Phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <input
                                type="text"
                                className="form-control custom-input"
                                style={{ backgroundColor: '#161826', color: 'white', height: '82px', borderRadius: '20px', opacity: '0.8', border: 'none' }}
                                placeholder="Address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <textarea
                                className="form-control custom-input"
                                rows="5"
                                style={{ backgroundColor: '#161826', color: 'white', height: '254px', borderRadius: '20px', opacity: '0.8', border: 'none' }}
                                placeholder="Enter Your Comments"
                                name="comments"
                                value={formData.comments}
                                onChange={handleChange}
                                required
                            ></textarea>
                           <br/>
                            <button
                                type="submit"
                                className="btn btn-primary mb-3 mb-md-0"
                                style={{ width: '175px', height: '40.676px', borderRadius: '6.893px', backgroundColor: '#43E1CF', color: 'black', fontWeight: '600', fontSize: '15.165px', fontFamily: 'Poppins' }}
                            >
                                Send a Message
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <br />
        </>
    );
};

export default Form;
