
import './BlogSummary.css';
import { useNavigate } from 'react-router-dom';

const BlogSummary = () => {
    const navigate = useNavigate();

    const handleLoadMore = () => {
        navigate('/blogs/dns-armor-for/business-security');
    };
    const handleLoadMore1 = () => {
        navigate('/blogs/dns-firewall-and-cybersecurity-regulations');
    };
    const handleLoadMore2 = () => {
        navigate('/blogs/dns-security-breaches-and-trends');
    };
    const handleLoadMore3 = () => {
        navigate('/blogs/understanding-dns-firewall');
    };
    const handleLoadMore4 = () => {
        navigate('/blogs/dns-essential-cyber-security');
    };
   

    return (
        <>
            <div className="blog-summary-container" style={{ backgroundColor: '#000312' }}>
                <div className="blog-content-wrapper">
                    <img
                        src="blog1.PNG"
                        alt="DNS Armor for Business Security"
                        className="blog-image"
                    />
                    <div className="blog-text-wrapper" style={{ color: 'white' }}>
                        <h1 className="blog-title">Top Benefits of Implementing DNS Armor for Business Security</h1>
                        <p className="blog-date">Published on: September 16, 2024</p>
                        <p className="blog-description">
                            In today's increasingly digital business environment, the security of your online presence is more crucial than ever. As cyber threats evolve, businesses must stay ahead of the curve by employing robust, scalable, and reliable security solutions. One such solution is DNS Armor, a SaaS Cloud DNS Firewall designed to protect your organization from a wide range of threats. Let's explore the top benefits of implementing DNS Armor for your business security.
                        </p>

                        <button className="load-more-button" onClick={handleLoadMore}>
                            Load More
                        </button>


                    </div>
                </div>
            </div>
            <div className="blog-summary-container" style={{ backgroundColor: '#000312' }}>
                <div className="blog-content-wrapper">
                    <img
                        src="blog8.PNG"
                        alt="DNS Armor for Business Security"
                        className="blog-image"
                    />
                    <div className="blog-text-wrapper" style={{ color: 'white' }}>
                        <h1 className="blog-title">DNS Firewall and Cybersecurity Regulations: A Critical Need</h1>
                        <p className="blog-date">Published on: September 19, 2024</p>
                        <p className="blog-description">
                            In today's cybersecurity environment, compliance with regulatory standards is not just a best practice—it's a legal obligation. Various regulations across industries mandate stringent security measures to protect sensitive data and maintain the integrity of critical infrastructures. DNS firewalls play a crucial role in meeting these requirements, providing a layer of protection that is often essential for compliance. This blog explores specific examples of cybersecurity regulations that highlight the importance of adopting DNS firewalls.
                        </p>

                        <button className="load-more-button" onClick={handleLoadMore1}>
                            Load More
                        </button>


                    </div>
                </div>
            </div>
            <div className="blog-summary-container" style={{ backgroundColor: '#000312' }}>
                <div className="blog-content-wrapper">
                    <img
                        src="blog4.PNG"
                        alt="DNS Armor for Business Security"
                        className="blog-image"
                    />
                    <div className="blog-text-wrapper" style={{ color: 'white' }}>
                        <h1 className="blog-title">DNS Security Breaches and Trends</h1>
                        <p className="blog-date">Published on: September 19, 2024</p>
                        <p className="blog-description">
                            The Domain Name System (DNS) is a critical component of the internet, acting as the phonebook that translates domain names into IP addresses. However, its essential role also makes it a prime target for cyberattacks. Over the past year, there have been several significant DNS security breaches and emerging attack trends that highlight the need for robust DNS security measures.
                        </p>
                        <button className="load-more-button" onClick={handleLoadMore2}>
                            Load More
                        </button>


                    </div>
                </div>
            </div>

            <div className="blog-summary-container" style={{ backgroundColor: '#000312' }}>
                <div className="blog-content-wrapper">
                    <img
                        src="blog5.PNG"
                        alt="DNS Armor for Business Security"
                        className="blog-image"
                    />
                    <div className="blog-text-wrapper" style={{ color: 'white' }}>
                        <h1 className="blog-title">Understanding DNS Firewalling: How It Protects Your Network</h1>
                        <p className="blog-date">Published on: September 19, 2024</p>
                        <p className="blog-description">
                        The internet has become the backbone of modern business, and with this reliance comes an increased need for robust security measures. One of the critical components of protecting your online presence is implementing a DNS firewall. But what exactly is a DNS firewall, and how does it safeguard your digital assets?
                        </p>
                        <button className="load-more-button" onClick={handleLoadMore3}>
                            Load More
                        </button>


                    </div>
                </div>
            </div>

            <div className="blog-summary-container" style={{ backgroundColor: '#000312' }}>
                <div className="blog-content-wrapper">
                    <img
                        src="blog6.PNG"
                        alt="DNS Armor for Business Security"
                        className="blog-image"
                    />
                    <div className="blog-text-wrapper" style={{ color: 'white' }}>
                        <h1 className="blog-title">Why DNS-Level Security is Essential for Modern Cyber Defense</h1>
                        <p className="blog-date">Published on: September 19, 2024</p>
                        <p className="blog-description">
                        In today’s cybersecurity landscape, protecting your network requires more than just traditional security measures like firewalls and intrusion detection systems. While these tools focus on monitoring and controlling data flow, they often miss critical threats that exploit the Domain Name System (DNS). DNS is responsible for translating domain names into IP addresses, making it a vital part of internet communication—and a prime target for cyberattacks. Implementing security at the DNS level is crucial for stopping threats early, before they can cause significant harm.
                        </p>
                        <button className="load-more-button" onClick={handleLoadMore4}>
                            Load More
                        </button>


                    </div>
                </div>
            </div>

            
        </>
    );
};

export default BlogSummary;
