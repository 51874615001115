import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
import '@fontsource/poppins/700.css'; // Weight 700

const ProjectKeyFeatures = () => {
  return (
    <div className="container my-4">
      <h2 className="fw-bold fs-2 fs-md-3 text-white text-center">
        Key Features of DNS Armor™
      </h2>
      <div className="text-center">
        <img src="pb3.png" className="img-fluid" alt="DNS Armor Key Features" />
      </div>
    </div>
  );
};

export default ProjectKeyFeatures;
