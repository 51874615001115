import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from "./Home";
import Contact from "./ContactUs";
import AboutUsPage from "./AboutUs";
import Product from "./Product";
import Media from "./Media";
import BlogSummary1 from "./components/Blogs/FullBlog";
import BlogSummary2 from "./components/Blogs/FullBlog1";
import BlogSummary3 from "./components/Blogs/FullBlog2";
import BlogSummary4 from "./components/Blogs/FullBlog3";
import BlogSummary5 from "./components/Blogs/FullBlog4";

const DynamicTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      "/": "Secure Domains - Home",
      "/about": "Secure Domains - About Us",
      "/contact": "Secure Domains - Contact Us",
      "/product": "Secure Domains - Products",
      "/blogs": "Secure Domains - Blogs",
    };

    const defaultTitle = "Secure Domains";

    document.title = pageTitles[location.pathname] || defaultTitle;
  }, [location]);

  return null; // This component doesn't render anything
};

const App = () => {
  return (
    <Router>
      <DynamicTitle />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product" element={<Product />} />
        <Route path="/blogs" element={<Media />} />
        <Route path="/blogs/dns-armor-for/business-security" element={<BlogSummary1 />} />
        <Route path="/blogs/dns-firewall-and-cybersecurity-regulations" element={<BlogSummary2 />} />
        <Route path="/blogs/dns-security-breaches-and-trends" element={<BlogSummary3 />} />
        <Route path="/blogs/understanding-dns-firewall" element={<BlogSummary4 />} />
        <Route path="/blogs/dns-essential-cyber-security" element={<BlogSummary5 />} />
      </Routes>
    </Router>
  );
};

export default App;
