import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins/400.css'; // Weight 400
const TextImageComponent = () => {
    return (
        <>
            <div className="container" style={{
                backgroundImage: 'url("pb1.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <h1 style={{ color: 'white', fontFamily: 'Poppins', fontWeight: '700', fontSize: '42px', marginTop: '20px' }}>Dns Security use cases</h1>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <p style={{ fontFamily: 'Poppins', color: '#43E1CF', fontWeight: '700', fontSize: '39.811px' }}>Malware Attacks</p>
                        <p style={{ fontSize: '15.312px', fontFamily: 'Poppins', fontWeight: '400', color: 'white' }}>A Malware attack can be executed in many ways and it has many forms,
                            ranging from viruses to spyware and adware. Malwares usually execute
                            unauthorized & malicious actions in the user’s device.
                            <br />
                            <br />
                            DNS ARMOR ™  offers targeted feeds designed to combat various types of
                            malware attacks. This unique approach complements existing security
                            solutions, filling gaps and alleviating the strain on those systems.
                            <br />
                            <br />
                            By proactively identifying and blocking threats at the DNS level,
                            organizations can enhance their overall cybersecurity posture.</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <img src="pro1.png" alt="Your Image" className="img-fluid" />
                    </div>
                </div>
            </div>

            <div
                className="container"
                style={{
                    backgroundImage: 'url("your-background-image-url.jpg")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    padding: '20px',
                    borderRadius: '8px'
                }}
            >

                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src="pro2.png" alt="Your Image" className="img-fluid" />
                    </div>
                    <div className="col-md-6">
                        <p style={{ fontFamily: 'Poppins', color: 'white', fontWeight: '700', fontSize: '39.811px' }}>
                            Ransomeware Attacks
                        </p>
                        <p style={{ fontSize: '15.312px', fontFamily: 'Poppins', fontWeight: '400', color: 'white' }}>
                            Ransomware is a special type of malware created with the intention of blocking
                            a user or an organization from accessing their computer files. Through the
                            encryption of these files and the subsequent demand for a ransom in
                            exchange for the decryption key.
                            <br />
                            <br />
                            DNS today is exploited by ransomwares in different techniques including key
                            exchange using DNS tunnels, visiting sites that can infect users with malicious
                            ransomware tools & software.


                        </p>
                    </div>
                </div>
            </div>

            <div className="container" style={{
                backgroundImage: 'url("pb2.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>

                <div className="row align-items-center">
                    <div className="col-md-6">
                        <p style={{ fontFamily: 'Poppins', color: '#43E1CF', fontWeight: '700', fontSize: '39.811px' }}>phishing Attacks</p>
                        <p style={{ fontSize: '15.312px', fontFamily: 'Poppins', fontWeight: '400', color: 'white' }}>Criminals exploit phishing attacks to entice individuals into visiting compromised
                            websites or downloading malicious software, with the sole purpose of stealing
                            sensitive information. By relying on threat intelligence services, DNS ARMOR™
                            can automatically block access to these abusive websites, even when users
                            are using their own devices within the corporate network.
                            <br/>
                            <br/>
                            This effectively reduces the risk of personal data theft from users who are
                            deceived into providing their credentials through fake malicious applications.
                            .</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <img src="pro3.png" alt="Your Image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TextImageComponent;
